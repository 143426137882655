import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import SEO from '../components/seo';

function RedirectTemplate({ pageContext: { title, url } }) {
  React.useEffect(() => {
    setTimeout(() => {
      window.location.replace(url);
    }, 200);
  }, [url]);
  return (
    <>
      <SEO nofollow title={title} />
      <Layout withoutHeader>
        <div className="event">
          <h1 style={{ fontSize: '2rem' }}>Tak for dit besøg på Zooticket</h1>
          <h2 style={{ fontSize: 24 }}>
            Vi håber du fandt lige den begivenhed du ledte efter{' '}
            <span role="img" aria-label="emoji">
              🎉
            </span>
          </h2>
        </div>
      </Layout>
    </>
  );
}

RedirectTemplate.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default RedirectTemplate;
